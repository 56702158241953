.homepage{
    display: flex;
    min-width: 100%;
    overflow-x: hidden;
    background-color:#1e1611 ;
    position: relative;
}
.content{
   flex-grow:1;
}
