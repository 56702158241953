.property-card{
    width: 500px;
    box-shadow: 3px 3px 3px 0 #8d8d82;
}
.property-card:hover{
    cursor: pointer;
}

.property-img{
    max-width: 100%;
    max-height: 300px;
    object-fit: contain;
    background-color: #f5f5f4;
}

.card-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rentalstatus{
    margin-left: 60px;
}
.zipcode{
    color: #8d8d82;
}