.propertyDetails{
    font-size: 40px;
    color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.title{
    display: flex;
    flex-direction: row; 
}

.propertyinfo{
    display: flex;
    flex-direction: column; 
}

.propertyinfo p{
    height: 20px;
}
.description{
    font-size: 20px;
    border-bottom: 2px solid #dddddd63;
    height: 50px;
}

.TenantsList{
    margin-bottom: 20px;
}

.ContractList{
    margin-bottom: 20px;
}