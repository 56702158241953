
.sidebar{
    display: flex; 
    width:200px;
    min-height: 100vh;
    flex-direction: column;
    align-items: baseline;
    background: #29221d;
}
.home-logo{
    overflow: hidden;
    align-self: center;
    margin-top: 50px;
}
.home-logo img{
    width:200px;
    height: px;
}

.home-logo:hover{
    cursor:pointer
}
.navigation{
    height: 600px;
    margin-top:28px;
    width:180px;
}


.nav-list li{
    margin-bottom:10px;
    margin-right:6px;
    cursor: pointer;
    height: 44px;
    border-radius: 20px;
    display: flex;
    align-items:center;
    padding: 6px 14px;
    column-gap: 12px;
    text-transform: capitalize;
    border: 1px solid transparent;
    transition: background-color 0.8s;
    color: #fff;
}
.nav-list li:hover{
    border: 1px solid #473b33;
    border-radius: 20px;
}
.nav-item.active{
    background-color: #fe6c00;
    border-radius: 20px;
    box-shadow: raba(0,0,0,0.1) 0px 20px 25px -5px,rgba(0,0,0,0.04)0 10px 10px -5px;
}