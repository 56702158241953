.tenant{
    font-size: 40px;
    color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.add-button button{
    background-color: aliceblue;
    color: black;
}
.ant-table-container{
    background-color: rgb(173, 166, 156);
}
.ant-pagination li{
    color: aliceblue;
    background-color: aliceblue !important;
}