.box{
    position: relative;
    width:380px;
    height:420px;
    border-radius: 12px;
}

.form{
    position: absolute;
    inset:2px;
    z-index: 10;
    border-radius: 8px;
    display: flex;
    padding: 50px 40px;
    flex-direction: column;
}
.form h2{
    color: black;
    font-weight: 500;
    text-align: center;
}
.inputBox{
    position: relative;
    width:300px;
    margin-top: 35px;
}

.inputBox input{
    position: relative;
    width: 100%;
    padding: 20px 10px 10px;
    background: transparent;
    border: none;
    outline: none;
    font-size: 1em;
    letter-spacing: 0.05em;
    z-index: 11;
    color: black;
}
.inputBox span{
    position:absolute;
    left:5px;
    font-size: 1em;
    bottom: 10px;
    pointer-events: none;
    transition:0.8s;
}
.inputBox input:valid ~span,
.inputBox input:focus ~span
{
    color:gray;
    transform: translateX(-4px) translateY(-40px);
    font-size: 0.9em;
}
.inputBox i{
    position:absolute;
    left:0;
    bottom:0;
    width:100%;
    height: 2px;
    background: wheat;
    border-radius: 4px;
    transition: 0.5s;
    pointer-events: none;
    z-index: 9;
}
.inputBox select{
    border-radius: 4px;
    
}
.inputBox input:valid ~ i,
.inputBox input:focus ~ i{
    height: 44px;
}
.error{
    color: red;
    margin: 10px;
}
.links{
    display: flex;
    justify-content: space-between;
}
.links a{
    margin:20px 0;
    font-size: 0.75em;
    color:gray;
    text-decoration: none;
}
.links a:hover,
.links a:nth-child(2){
    color:black
}
input[type="submit"]
{
    border:none;
    outline: none;
    background: wheat;
    padding:11px 25px;
    width: 100px;
    margin-top: 10px;
    border-radius: 4px;
    font-weight:600;
    cursor:pointer;
}
input[type="submit"]:active{
    opacity: 0.8;
}
:root{
    font-size:15px;
}

.login,.register{
    display: flex;
    justify-content: center;
    align-items: center;
    margin:0;
    min-height: 100vh;
    background-color: #e493d0;
    background-image: radial-gradient(closest-side,rgba(235,105,78,1),rgba(235,105,78,0)),
    radial-gradient(closest-side,rgba(248,192,147,1),rgba(248,192,147,0)),
    radial-gradient(closest-side,rgba(170,142,245,1),rgba(170,142,245,0)),
    radial-gradient(closest-side,rgba(243,11,164,1),rgba(243,11,164,0)),
    radial-gradient(closest-side,rgba(254,234,131,1),rgba(254,234,131,0));

    background-size:
        130vmax 130vmax,
        80vmax 80vmax,
        90vmax 90vmax,
        110vmax 110vmax,
        90vmax 90vmax;

    background-position: 
    -80vmax -80vmax,
    60vmax -30vmax,
    -30vmax -10vmax,
    50vmax 50vmax,
    10vmax 10vmax;

    background-repeat: no-repeat;
    animation: 10s movement linear infinite;

    &::after{
        content:'';
        display: block;
        position: fixed;
        width:100%;
        height: 100%;
        top:0;
        left:0;
        backdrop-filter:blur(10px);
        -webkit-backdrop-filter: blur(10px);
        z-index: -1;
    }
}


@keyframes movement{
    0%,100%{
        background-size:
        130vmax 130vmax,
        80vmax 80vmax,
        90vmax 90vmax,
        110vmax 110vmax,
        90vmax 90vmax;

    background-position: 
        -80vmax -80vmax,
        60vmax -30vmax,
        30vmax -10vmax,
        50vmax 50vmax,
        10vmax 10vmax;
    }
    25%{
        background-size:
        100vmax 100vmax,
        90vmax 90vmax,
        80vmax 80vmax,
        100vmax 100vmax,
        70vmax 70vmax;

    background-position: 
        -60vmax -90vmax,
        50vmax -40vmax,
        30vmax -10vmax,
        40vmax 40vmax,
        20vmax 20vmax;
    }
    50%{
        background-size:
        120vmax 120vmax,
        70vmax 70vmax,
        60vmax 60vmax,
        90vmax 90vmax,
        80vmax 80vmax;

    background-position: 
        -70vmax -80vmax,
        60vmax -30vmax,
        20vmax -20vmax,
        30vmax 30vmax,
        40vmax 40vmax;
    }
    75%{
        background-size:
        140vmax 140vmax,
        60vmax 60vmax,
        80vmax 80vmax,
        110vmax 110vmax,
        70vmax 70vmax;

    background-position: 
        -80vmax -70vmax,
        70vmax -40vmax,
        30vmax -10vmax,
        50vmax 50vmax,
        30vmax 30vmax;
    }
    
}