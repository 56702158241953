.header{
    width: 100%;
    min-height: 25px;
    display: flex;
    justify-content:flex-end;
    font-size: 14px;
    margin-top: 10px;
    border-bottom: 1px solid gray; 
    padding-bottom: 15px; 
    box-shadow:0 2px 0 #ffffffdb;
}
.notification{
    padding: 10px;
}
.notification:hover{
    border: 1px solid gray;
    border-radius: 20px;
}
.user{
    display: flex;
    margin-right: 20px;
    margin-left: 10px;
    padding: 10px;
    background-color: #ffffffdb;
    border-radius: 20px;
}

.usericon{
    padding-left: 10px;
}
.user:hover{
    border: 1px solid gray;
    border-radius: 20px;
    cursor: pointer;
}
.user{
    display: flex;
}
.dropdown{
    position: absolute;
    padding-top: 20px;
    right:20px;
    z-index: 1000;
}
.dropdown ul{
    padding-top: 10px;
    padding-bottom:10px;
    padding-left:15px;
    padding-right:10px;
    border: 1px solid gray;
    border-radius: 10px;
    box-shadow: 3px 3px 5px #82827a;
    background-color: #29221d;
}
.dropdown li{
    display: flex;
    color: aliceblue;
    margin-bottom: 7px;
    letter-spacing: 0.2px;
}


@media (max-width: 600px) {
        .username {
          display: none;
        }
}
