.profile{
    color: white;
    font-size: 40px;
    padding: 20px;
    
}

.profile-box{
    max-width: 60%;
    margin: 0 auto;
}
.accordion-item{
    display:flex;
    justify-content: space-between;
    border-bottom: 1px solid #dddddd63;
    
}

.info-area{
    display: flex;
    font-size:medium;
    align-items: center;
    
}

.info-text{
    margin-right: 20px;
}

.email-verify{
    pad: 20px;
}

.email-button{
    display: flex;
    flex-direction: column;
}
.resend-button{
    margin-top: 20px;
}
.resend-button:disabled {
    color: #534c4c;
    background-color: #1890ff; 
    border-color: #1890ff; 
    cursor: not-allowed; 
    opacity: 1;  
}