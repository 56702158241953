
.home{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; 
    margin: 0;
}

.title{
    font-size: 30px;
}
.infomation{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 600px;
    height: 600px;
    border-radius: 12px;
    color: rgb(212, 198, 198);
    border: 2px solid wheat;
    font-size: 20px;
}