.repair{
    font-size: 40px;
    color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.ant-table-tbody > tr > td.ant-table-column-sort {
    background: transparent !important;
}