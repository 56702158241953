.autoCompleteInput{
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 90%;
    padding: 7px 0px;
    color: #000;
    outline-color: #4096ff;
    left: 20px;
    text-indent: 10px;
}


